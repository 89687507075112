import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {
  Delete,
  Visibility,
} from '@mui/icons-material';
import BButton from '../base/BButton.jsx';
import colors from '../../styles/colors.scss';
import {MAX_LENGTH_GROUP_NAME} from '../../constants/groups';
import {AlertContext} from '../../contexts/Alert';
import {updateGroup} from '../../services/api/apiGroups';
import BInputText from '../base/BInputText.jsx';

const Group = ({
  initialName,
  numberOfMembers = 0,
  onGroupNameBlur,
  groupId,
  onButtonMembersClick,
  onButtonDeleteClick,
}) => {
  const {setAlertMessage} = useContext(AlertContext);

  const [groupName, setGroupName] = useState(initialName);
  const [isGroupNameInvalid, setIsGroupNameInvalid] = useState(false);

  return (
    <Stack
      spacing={2}
      direction={'column'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      sx={{
        // height: '290px',
        borderRadius: '20px',
        overflow: 'hidden',
        padding: '24px',
        backgroundColor: colors.white,
        border: `1px solid ${colors.greyMid}`,
      }}
    >
      <Stack
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        flexGrow={1}
        spacing={2}
      >
        <BInputText
          error={isGroupNameInvalid}
          inputProps={{maxLength: MAX_LENGTH_GROUP_NAME}}
          placeholder={'Название группы'}
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
            setIsGroupNameInvalid(false);
          }}
          onBlur={async () => {
            const newGroupName = groupName.trim();
            if (newGroupName && (newGroupName !== initialName)) {
              try {
                await updateGroup({name: newGroupName, id: groupId});
                setGroupName(newGroupName);
                onGroupNameBlur();
              } catch (error) {
                setIsGroupNameInvalid(true);
                setAlertMessage({
                  content: error?.response?.status === 400
                    ? 'Уже существует группа с таким названием!'
                    : 'Что-то пошло не так!',
                  variant: 'warning',
                });
              }
            } else {
              setGroupName(initialName);
            }
          }}
        />
        <BButton
          sx={{padding: '12px'}}
          content={<Delete fontSize={'small'}/>}
          onClick={onButtonDeleteClick}
        />
      </Stack>
      <Divider/>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant={'h3'}>
          Кол-во студентов: {numberOfMembers}
        </Typography>
        <BButton
          sx={{padding: '12px'}}
          content={<Visibility fontSize={'small'}/>}
          onClick={onButtonMembersClick}
        />
      </Stack>
    </Stack>
  );
};

Group.propTypes = {
  onButtonMembersClick: PropTypes.func.isRequired,
  onButtonDeleteClick: PropTypes.func.isRequired,
  onGroupNameBlur: PropTypes.func.isRequired,
  initialName: PropTypes.string.isRequired,
  numberOfMembers: PropTypes.number,
  groupId: PropTypes.number.isRequired,
};

export default Group;
