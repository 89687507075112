import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BButton from './BButton.jsx';
import colors from '../../styles/colors.scss';

const BModal = ({
  open,
  onClose,
  title,
  titleStyles,
  children,
  withFooterButtons = true,
  buttonAlignment = 'flex-end',
  onOutlineFooterBtnClick,
  onPrimaryFooterBtnClick,
  maxWidth = '560px',
  outlineFooterBtnContent = 'Отмена',
  primaryFooterBtnContent = 'Сохранить',
  isPrimaryFooterBtnDisable = false,
}) => {
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          padding: '50px',
          borderRadius: '20px',
          maxHeight: '95vh',
          boxSizing: 'border-box',
          maxWidth,
        },
      }}
    >
      <DialogTitle sx={{
        marginBottom: '32px',
        padding: 0,
        ...titleStyles,
      }}
      >
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 25,
            top: 25,
            color: colors.greyDark,
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          overflow: 'auto',
          marginBottom: withFooterButtons ? 0 : '32px',
        }}
      >
        {children}
      </Box>
      {withFooterButtons && <Stack
        direction={'row'}
        justifyContent={buttonAlignment}
        alignItems={'center'}
        spacing={2}
        sx={{marginTop: '32px'}}
      >
        <BButton
          variant={'outlined'}
          onClick={() => onOutlineFooterBtnClick()}
          content={outlineFooterBtnContent}
        />
        <BButton
          variant={'contained'}
          onClick={() => onPrimaryFooterBtnClick()}
          content={primaryFooterBtnContent}
          disabled={isPrimaryFooterBtnDisable}
        />
      </Stack>}
    </Dialog>
  );
};

BModal.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyles: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  onOutlineFooterBtnClick: PropTypes.func,
  onPrimaryFooterBtnClick: PropTypes.func,
  outlineFooterBtnContent: PropTypes.string,
  primaryFooterBtnContent: PropTypes.string,
  isPrimaryFooterBtnDisable: PropTypes.bool,
  withFooterButtons: PropTypes.bool,
  maxWidth: PropTypes.string,
  buttonAlignment: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-between',
  ]),
};

export default BModal;
