import {httpRequest} from './apiHelper';

function getSchedule({
  lessonId = null,
  groupId = null,
  startDateUtc = null,
  endDateUtc = null,
}) {
  const params = [
    !!lessonId && `LessonsId=${lessonId}`,
    !!groupId && `GroupId=${groupId}`,
    !!startDateUtc && `StartDateUtc=${startDateUtc}`,
    !!endDateUtc && `EndDateUtc=${endDateUtc}`,
  ];
  let url = '/Lesson/GetScheduleLessonsWithGroup';
  if (params.map((value) => !!value).includes(true)) {
    url += '?';
    url = params.reduce(
      (previousUrl, currentParam) => {
        if (currentParam) {
          return `${previousUrl}&${currentParam}`;
        }
        return previousUrl;
      },
      url,
    );
  }
  return httpRequest(`${url}`);
}

function addLesson(data) {
  return httpRequest('/Lesson/AddLesson', 'post', data);
}

function editLesson(lessonId, data) {
  return httpRequest(`/Lesson/${lessonId}/UpdateLesson`, 'put', data);
}

function removeLesson(lessonId) {
  return httpRequest(`/Lesson/${lessonId}/RemoveLesson`, 'delete');
}

export {
  getSchedule,
  addLesson,
  editLesson,
  removeLesson,
};
