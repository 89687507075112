import React, {useEffect} from 'react';
import {OutlinedInput} from '@mui/material';
import PropTypes from 'prop-types';

const BInputText = ({
  placeholder = 'Введите данные',
  size = 'small',
  inputStyles = {},
  isAutoFocus = false,
  ...props
}) => {
  const inputRef = React.useRef();

  useEffect(() => {
    let autoFocusTimeout;
    if (isAutoFocus) {
      autoFocusTimeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }

    return () => {
      clearTimeout(autoFocusTimeout);
    };
  }, [isAutoFocus]);
  return (
    <OutlinedInput
      inputRef={inputRef}
      fullWidth
      size={size}
      placeholder={placeholder}
      sx={{...inputStyles}}
      variant={'outlined'}
      {...props}
    />
  );
};

BInputText.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.string,
  inputStyles: PropTypes.object,
  isAutoFocus: PropTypes.bool,
};

export default BInputText;
