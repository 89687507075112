import React, {
  useContext, useEffect, useMemo,
  useState,
} from 'react';
import {Typography} from '@mui/material';
import {AlertContext} from '../contexts/Alert';
import {
  getUsers,
  putUser,
} from '../services/api/apiUsers';
import EnglishLevelInput from '../components/EnglishLevelInput.jsx';
import BSelect from '../components/base/BSelect.jsx';
import BTable from '../components/base/BTable.jsx';

// USERS TABLE
const headers = [
  {
    title: 'Имя Фамилия',
    key: 'name',
  },
  {
    title: 'Уровень английского',
    key: 'englishLevel',
  },
  {
    title: 'Группа',
    key: 'group',
  },
  {
    title: 'Должность',
    key: 'position',
  },
];

const UsersManagement = () => {
  const {setAlertMessage} = useContext(AlertContext);

  // USERS;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // USERS: get data
  const getUsersData = async () => {
    try {
      const users = (await getUsers()).data;
      setUsers(users);
    } catch {
      setAlertMessage({
        content: 'Возникли проблемы с загрузкой пользователей!',
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    getUsersData();
  }, []); // eslint-disable-line

  async function putUserNewData(user) {
    try {
      await putUser(user);
      setUsers([...users.map((member) => {
        if (member.id === user.id) {
          return {...member, languageLevel: user.languageLevel};
        }
        return member;
      })]);
      setAlertMessage({
        content: 'Данные пользователя обновлены!',
        variant: 'success',
      });
    } catch (e) {
      setAlertMessage({
        content: 'Данные не сохранены!',
        variant: 'warning',
      });
    }
  }

  // USERS: for render and search
  const usersForTable = useMemo(() => {
    const filteredUsers = selectedUsers.length > 0
      ? users.filter((user) => selectedUsers.some(((member) => member.id === user.id)))
      : users;
    if (filteredUsers && filteredUsers.length > 0) {
      return filteredUsers.map((user) => {
        return {
          key: user.id,
          name: <Typography
            variant={'body1'}
          >
            {user.fullName}
          </Typography>,
          englishLevel: <EnglishLevelInput
            initialEnglishLevel={`${user.languageLevel ? user.languageLevel : ''}`}
            onEnglishLevelBlur={(value) => {
              if (user.languageLevel !== value) {
                putUserNewData({
                  ...user,
                  languageLevel: value,
                });
              }
            }}
            placeholder={'Введите уровень'}
          />,
          group: <Typography
            variant={'body1'}
          >
            {`${user.groupName ? user.groupName : 'Без группы'}`}
          </Typography>,
          position: <Typography
            variant={'body1'}
          >
            {user.position}
          </Typography>,
        };
      });
    }
    return [];
    // eslint-disable-next-line
  }, [users, selectedUsers]);

  return (
    <main>
      <Typography
        variant={'h1'}
        sx={{marginBottom: '50px'}}
      >
        Список пользователей
      </Typography>
      <BSelect
        withSearchIcon
        isMultiple
        isVirtual
        optionLabel={'fullName'}
        placeholder={'Поиск пользователя'}
        selectedValues={selectedUsers}
        onChange={(users) => setSelectedUsers(users)}
        options={users}
        sx={{
          marginBottom: '50px',
          maxWidth: '600px',
        }}
      />
      <BTable
        headers={headers}
        rows={usersForTable}
        rowsPerPage={10}
        withPagination
        onPageChange={() => {
        }}
        stylesTableRow={{
          padding: '14px',
        }}
      />
    </main>
  );
};

export default UsersManagement;
