const setCookie = (name, value, lifetime = '86400') => {
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; max-age=${lifetime}`;
};

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)', // eslint-disable-line
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

export {setCookie, getCookie, deleteCookie};
