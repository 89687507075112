import React, {forwardRef, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  styled,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Stack,
} from '@mui/material';
import {
  ArrowBackIosNewRounded,
  Logout,
  Article,
  CalendarToday,
  Person,
  PeopleOutline, ArrowForwardIosRounded,
} from '@mui/icons-material';
import cl from './Sidebar.module.scss';
import colors from '../../styles/colors.scss';
import useAuth from '../../hooks/useAuth';
import logo from '../../assets/logo.svg';
import logoWithTitle from '../../assets/logoWithTitle.svg';
import usePermissions from '../../hooks/usePermissions';
import {getCookie} from '../../services/cookie';

const BNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({isActive}) => `${cl.link} ${isActive ? cl.link_active : ''}`}
  />
));
BNavLink.displayName = 'BNavLink';

const Sidebar = ({
  open,
  handleToggleDrawer,
}) => {
  const {logout} = useAuth();
  const {hasPermission} = usePermissions();

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [open]);

  const sidebarData = [
    {
      title: 'Список пользователей',
      icon: <PeopleOutline/>,
      href: '/user-management',
      permission: hasPermission('canManageUsers'),
    },
    {
      title: 'Список групп',
      icon: <Article/>,
      href: '/group-management',
      permission: hasPermission('canManageGroups'),
    },
    {
      title: 'Расписание занятий',
      icon: <CalendarToday/>,
      href: '/calendar',
      permission: true,
    },
    {
      title: 'Выход',
      icon: <Logout/>,
      href: '/',
      permission: true,
      onClick: logout,
    },
  ];

  const openedMixin = (theme) => ({
    width: '350px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    width: '94px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  });

  const StyledDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
      width: '350px',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return (
    <StyledDrawer
      variant={'permanent'}
      open={open}
    >
      <List sx={{
        padding: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={0}
          sx={{
            ml: '23px',
            padding: '25px 23px 0 0',
          }}
          onClick={handleToggleDrawer}
        >
          <IconButton
            disableRipple
            sx={{
              padding: 0,
              position: 'relative',
            }}
          >
            <img
              style={{height: '48px'}}
              src={open ? logoWithTitle : logo}
              alt={'logo'}
            />
            {
              !open
              && <ArrowForwardIosRounded
                className={cl['show-bar-btn']}
              />
            }
          </IconButton>
          {open && <IconButton disableRipple>
            <ArrowBackIosNewRounded/>
          </IconButton>
          }
        </Stack>
        <p className={`${cl.username} ${!open ? cl.username_collapse : ''}`}>
          <Person sx={{
            color: colors.greyMid,
            mr: `${open ? '24px' : '0'}`,
            minWidth: `${open ? '56px' : '0'}`,
          }}/>
          {
            open
              ? getCookie('username')
              : getCookie('username')
                .split(' ', 2)
                .map((initial) => initial[0])
                .join()
                .toUpperCase()
          }
        </p>
        <Divider style={{width: '100%'}}/>
        <Stack
          direction={'column'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          spacing={0}
          flexGrow={1}
        >
          {sidebarData.map((item, index) => (
            item.permission
            && <React.Fragment key={item.title}>
              <ListItem
                component={BNavLink}
                disablePadding
                style={
                  sidebarData.length - 1 === index
                    ? {
                      marginTop: 'auto',
                      marginBottom: '45px',
                    }
                    : {}
                }
                sx={{display: 'block'}}
                to={item.href}
              >
                {sidebarData.length - 1 === index && <Divider style={{width: '100%'}}/>}
                <ListItemButton
                  disableRipple
                  sx={{
                    height: '56px',
                    backgroundColor: 'inherit',
                  }}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: open ? '24px' : 'auto',
                      justifyContent: 'center',
                      color: colors.greyMid,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && <ListItemText
                    primary={item.title}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '16px',
                      },
                    }}
                  />}
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </Stack>
      </List>
    </StyledDrawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
