import React from 'react';
import {Outlet} from 'react-router-dom';
import {Stack} from '@mui/material';
import logoWithTitle from '../../assets/logoWithTitle.svg';
import cl from './Empty.module.scss';

const Empty = () => {
  const year = (new Date()).getFullYear();

  return (
    <Stack sx={{height: '100%', flexGrow: 1}}>
      <header className={cl.header}>
        <img
          alt={'Belitsoft logo'}
          src={logoWithTitle}
          className={cl.header__logo}
        />
        <span className={cl.header__title}>
          English Management
        </span>
      </header>
      <Outlet/>
      <footer className={cl.footer}>
        {year} Belitsoft, all rights reserved
      </footer>
    </Stack>
  );
};

export default Empty;
