const SERVERERRORSMESSAGES = {
  600: 'Что-то пошло не так!',
  601: 'Некоторые из добавляемых пользователей уже учавствуют в других группах!',
  602: 'Данные не найдены',
  603: 'Название или имя уже используется!',
  604: 'Не верный логин или пароль, аккаунт может быть заблокирован!',
  605: 'Аккаун заблокирован, обратитесь к системному администратору',
};

export {SERVERERRORSMESSAGES};
