import {intervalToDuration} from 'date-fns';
import colors from '../styles/colors.scss';
import {DAYS} from '../constants/calendar';
import {formatTimeToCorrectDate} from './formatTime';

const randomIntFromInterval = (min, max) => { // min and max included
  return Math.floor((Math.random() * (max - min + 1)) + min);
};

const getEventColor = () => {
  const eventColors = [
    colors.red,
    colors.orange,
    colors.yellow,
    colors.blueMid,
    colors.purple,
    colors.green,
  ];
  return eventColors[randomIntFromInterval(0, eventColors.length - 1)];
};

const convertLessons = (lesson) => {
  const commonProperties = {
    id: lesson.id,
    title: lesson.group.name,
    backgroundColor: getEventColor(), // todo: not random color
    isRepeat: lesson.isRepeat,
  };

  if (!lesson.isRepeat) {
    return {
      start: formatTimeToCorrectDate(lesson.lessonStartTimeUtc, lesson.lessonStartDateUtc),
      end: formatTimeToCorrectDate(lesson.lessonEndTimeUtc, lesson.untilDateUtc),
      ...commonProperties,
    };
  }

  // for recurring events
  const lessonDuration = intervalToDuration({
    start: new Date(lesson.lessonStartTimeUtc),
    end: new Date(lesson.lessonEndTimeUtc),
  });

  return {
    rrule: {
      freq: 'weekly',
      byweekday: DAYS.filter((day) => lesson.daysOfWeek
        .indexOf(day.valueForRequest) >= 0)
        .map((filtered) => filtered.valueForCalendar),
      dtstart: lesson.lessonStartDateUtc,
      // until: add(new Date(lesson.untilDateUtc), {days: 1}),
      until: new Date(lesson.untilDateUtc),
    },
    duration: lessonDuration,
    exdate: lesson.exdates, // time should be the same time as the start date for repetitions (dtstart)
    ...commonProperties,
  };
};
export {convertLessons};
