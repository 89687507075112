import React from 'react';
import {Typography} from '@mui/material';
import LoginForm from '../../components/LoginForm.jsx';
import cl from './Login.module.scss';

const Login = () => {
  return (
    <div className={`${cl.container} background-gradient`}>
      <Typography
        variant={'h1'}
        sx={{
          marginBottom: '40px',
          fontSize: '28px',
          lineHeight: '26px',
        }}
      >
        Вход в систему
      </Typography>
      <LoginForm/>
    </div>
  );
};

export default Login;
