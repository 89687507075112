import axios from 'axios';
import {deleteCookie, getCookie, setCookie} from '../cookie';

const API_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api`;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export function httpRequest(url, method = 'get', body = null, config = {}) {
  return axiosInstance[method](url, body, config);
}

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getCookie('accessToken')}`;
  return config;
});

function postRefreshToken() {
  return httpRequest('/Account/RefreshToken', 'post', {
    refreshToken: getCookie('refreshToken'),
    accessToken: getCookie('accessToken'),
  });
}

axiosInstance.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error?.response.status === 401) {
      try {
        const response = await postRefreshToken();
        const {accessToken, refreshToken} = response.data;
        setCookie('accessToken', accessToken);
        setCookie('refreshToken', refreshToken);
      } catch {
        deleteCookie('accessToken');
        deleteCookie('refreshToken');
        deleteCookie('role');
        document.location.reload();
      }
    } else {
      throw error;
    }
  },
);
