const getErrorValidationMessages = (errorType, options = {}) => {
  switch (errorType) {
  case 'required':
    return 'Поле обязательно для заполнения!';

  case 'maxLength':
    return `Количество символов не должно превышать ${options.max}!`;

  case 'minLength':
    return `Количество символов не должно быть меньше ${options.min}!`;

  default:
    return 'Данное поле не является валидным!';
  }
};

export {getErrorValidationMessages};
