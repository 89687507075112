import React, {
  useState,
  createContext,
  forwardRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {createFilterOptions} from '@mui/material';
import {FixedSizeList} from 'react-window';
import {styled} from '@mui/material/styles';
import Popper from '@mui/material/Popper';

const BSelect = ({
  options,
  optionLabel,
  onChange,
  selectStyles = {},
  disabled = false,
  isMultiple = false,
  isVirtual = false, // only for multi select
  isSelectAll = false, // only for multi select
  placeholder = 'Выберите значение',
  selectAllLabel = 'Выбрать все',
  selectedValue,
  selectedValues = [],
  noOptionsText = 'Ничего не найдено',
  withSearchIcon = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const allSelected = options.length === selectedValues.length;

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      onChange(options);
    } else {
      onChange([]);
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedValues, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (isSelectAll && selectedValues.find((option) => option.value === 'select-all')) {
        handleToggleSelectAll();
        const result = options.filter((el) => el.value !== 'select-all');
        return onChange(result);
      }
      return onChange(selectedValues);
    }
    if (reason === 'clear') {
      return onChange([]);
    }
    return null;
  };

  const getOptionLabel = (option) => `${option[optionLabel]}`;
  const getOptionSelected = (option, anotherOption) => option[optionLabel] === anotherOption[optionLabel];
  const filter = createFilterOptions();

  const optionRenderer = (props, option, {selected}) => {
    const selectAllProps = (isSelectAll && option.value === 'select-all')
      ? {checked: allSelected}
      : {};

    return (
      <li {...props} style={{justifyContent: 'space-between'}}>
        {getOptionLabel(option)}
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize='small'/>}
          checkedIcon={<CheckBoxIcon fontSize='small'/>}
          checked={selected}
          {...selectAllProps}
        />
      </li>
    );
  };

  const inputRenderer = (params) => {
    return (
      <TextField
        {...params}
        placeholder={(selectedValues.length > 0 && isMultiple) ? '' : placeholder}
        InputProps={{
          ...params.InputProps,
          startAdornment: withSearchIcon ? (
            <>
              <InputAdornment position={'start'} sx={{marginLeft: '5px'}}>
                <SearchIcon/>
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ) : params.InputProps.startAdornment,
        }}
      />
    );
  };

  // VIRTUALIZATION
  const LISTBOX_PADDING = 8; // px

  const renderRow = ({data, index, style}) => {
    const dataSet = data[index];

    const rowProps = dataSet[0];
    const option = dataSet[1];
    const selected = dataSet[2];

    const inlineStyle = {
      ...style,
      top: style.top + LISTBOX_PADDING,
    };

    // todo: custom checkbox
    return (
      <li
        {...rowProps}
        style={{
          justifyContent: 'space-between',
          ...inlineStyle,
        }}
      >
        {option.fullName}
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize='small'/>}
          checkedIcon={<CheckBoxIcon fontSize='small'/>}
          checked={selected}
        />
      </li>
    );
  };

  const OuterElementContext = createContext({});
  const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps}/>;
  });
  OuterElementType.displayName = 'OuterElementType';

  // ADAPTER FOR "react-window"
  const ListboxComponent = forwardRef((props, ref) => {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 36; // px

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.length * itemSize;
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={itemData}
            height={getHeight() + (2 * LISTBOX_PADDING)}
            width={'100%'}
            outerElementType={OuterElementType}
            innerElementType={'ul'}
            itemSize={36}
            overscanCount={1} // The number of items to render outside of the visible area
            itemCount={itemCount}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  ListboxComponent.displayName = 'ListboxComponent';
  ListboxComponent.propTypes = {
    children: PropTypes.array,
  };

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  return (
    <Autocomplete
      multiple={isMultiple}
      size={'small'}
      disableClearable={!isMultiple}
      // disablePortal
      // limitTags={limitTags}
      disableListWrap={isVirtual}
      PopperComponent={isVirtual ? StyledPopper : null}
      ListboxComponent={isVirtual ? ListboxComponent : null}
      disabled={disabled}
      options={options}
      value={isMultiple ? selectedValues : selectedValue}
      disableCloseOnSelect={isMultiple}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (isSelectAll) {
          return [{
            [optionLabel]: selectAllLabel,
            value: 'select-all',
          }, ...filtered];
        }
        return filtered;
      }}
      onChange={(event, newValue, reason) => {
        if (isMultiple) {
          handleChange(event, newValue, reason);
        } else {
          onChange(newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={isMultiple
        ? (props, option, {selected}) => {
          if (isVirtual) {
            return [props, option, selected];
          }
          return optionRenderer(props, option, {selected});
        }
        : null
      }
      renderInput={inputRenderer}
      sx={{
        width: '100%',
        ...selectStyles,
      }}
      {...props}
    />
  );
};

BSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectStyles: PropTypes.object,
  placeholder: PropTypes.string,
  selectAllLabel: PropTypes.string,
  optionLabel: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string,
  selectedValues: PropTypes.array,
  selectedValue: PropTypes.object,
  disabled: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isVirtual: PropTypes.bool,
  withSearchIcon: PropTypes.bool,
};

export default BSelect;
