import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel} from '@mui/material';
import colors from '../../styles/colors.scss';

const StyledCheckBox = (props) => {
  return (
    <Checkbox
      disableRipple
      sx={{
        color: colors.greyMid,
        '&.Mui-checked': {
          color: colors.purple,
        },
      }}
      size={'small'}
      {...props}
    />
  );
};

const BCheckBox = ({
  checked,
  onChange,
  withLabel = false,
  label = 'Нажмите, чтобы выбрать', // text or element
  labelPlacement = 'end',
  labelStyles = {},
  ...props
}) => (
  withLabel
    ? <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      checked={checked}
      onChange={onChange}
      control={<StyledCheckBox {...props}/>}
      sx={{
        marginRight: 0,
        '&.MuiFormControlLabel-root': {
          ...labelStyles,
        },
      }}
    />
    : <StyledCheckBox
      checked={checked}
      onChange={onChange}
      {...props}
    />
);

BCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  withLabel: PropTypes.bool,
  labelPlacement: PropTypes.oneOf([
    'top', 'start', 'bottom', 'end',
  ]),
  labelStyles: PropTypes.object,
  label: PropTypes.node,
};

export default BCheckBox;
