import React, {useState} from 'react';
import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@mui/material';
import routes from './routes';
import {withProviders} from './HOC/withProvider';
import useAuth, {AuthProvider} from './hooks/useAuth';
import usePermissions, {PermissionsProvider} from './hooks/usePermissions';
import commonTheme from './themes/common';
import './App.scss';
import BAlert from './components/base/BAlert/BAlert.jsx';
import {AlertContext} from './contexts/Alert';

function App() {
  const {authed} = useAuth();
  const {hasPermission, userRole} = usePermissions();
  const routing = useRoutes(routes(authed, userRole, hasPermission));

  const [alertMessage, setAlertMessage] = useState();

  return (
    <div className={'App'}>
      <ThemeProvider theme={commonTheme}>
        <AlertContext.Provider
          value={{
            setAlertMessage,
          }}
        >
          <BAlert
            message={alertMessage}
            clearContent={() => setAlertMessage({})}
          />
          {routing}
        </AlertContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default withProviders(
  PermissionsProvider,
  AuthProvider,
)(
  App,
);
