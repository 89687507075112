const DAYS = [
  {
    name: 'monday',
    labelEng: 'Mo',
    labelRu: 'Пн',
    valueForRequest: 1,
    valueForCalendar: 0,
  },
  {
    name: 'tuesday',
    labelEng: 'Tu',
    labelRu: 'Вт',
    valueForRequest: 2,
    valueForCalendar: 1,
  },
  {
    name: 'wednesday',
    labelEng: 'We',
    labelRu: 'Ср',
    valueForRequest: 4,
    valueForCalendar: 2,
  },
  {
    name: 'thursday',
    labelEng: 'Th',
    labelRu: 'Чт',
    valueForRequest: 8,
    valueForCalendar: 3,
  },
  {
    name: 'friday',
    labelEng: 'Fr',
    labelRu: 'Пт',
    valueForRequest: 16,
    valueForCalendar: 4,
  },
];

export {DAYS};
