import React, {useContext, useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import useAuth from '../hooks/useAuth';
import BInputText from './base/BInputText.jsx';
import BButton from './base/BButton.jsx';
import {AlertContext} from '../contexts/Alert';
import colors from '../styles/colors.scss';
import {getErrorValidationMessages} from '../utils/getErrorValidationMessages';
import {setCookie} from '../services/cookie';
import {SERVERERRORSMESSAGES} from '../constants/errors';

const LoginForm = () => {
  const {setAlertMessage} = useContext(AlertContext);
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const {login} = useAuth();
  const [userFormData, setUserFormData] = useState({
    login: '',
    password: '',
  });
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleUserFormData = (e) => {
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const loginData = [
    {
      value: userFormData,
      handler: handleUserFormData,
      key: 'login',
      options: {
        placeholder: 'Your JIRA/WIFI login like ivanov_i',
        type: 'text',
        inputProps: {
          sx: {
            padding: '15px 20px',
          },
        },
        rules: {
          required: true,
        },
      },
    },
    {
      value: userFormData,
      handler: handleUserFormData,
      key: 'password',
      options: {
        placeholder: 'Password',
        type: isShowPassword ? 'text' : 'password',
        inputProps: {
          sx: {
            padding: '15px 0 15px 20px',
          },
        },
        rules: {
          required: true,
        },
      },
    },
  ];

  const handleSubmitForm = () => {
    login(userFormData)
      .then(() => setCookie('username', userFormData.login))
      .catch((error) => {
        setLoginErrorMessage(SERVERERRORSMESSAGES[error.response.data.statusCode]);
        setAlertMessage({
          content: SERVERERRORSMESSAGES[error.response.data.statusCode],
          variant: 'warning',
        });
      });
  };

  // clean state in unmount of the component
  useEffect(() => (
    () => {
      setUserFormData({
        login: '',
        password: '',
      });
    }
  ), []);

  return (
    <Box
      sx={{
        maxWidth: '505px',
        width: '100%',
        backgroundColor: '#FFFFFF4D',
        padding: '32px',
        borderRadius: '10px',
        boxSizing: 'border-box',
      }}
    >
      <Stack
        spacing={0}
        sx={{
          padding: '32px',
          backgroundColor: colors.white,
          borderRadius: '10px',
        }}
      >
        {!!loginErrorMessage && (
          <FormHelperText error>
            {loginErrorMessage}
          </FormHelperText>
        )}
        {
          loginData.map((item) => (
            <Controller
              rules={item.options.rules}
              key={item.key}
              name={item.key}
              control={control}
              render={({
                field: {
                  onChange,
                },
              }) => <FormControl>
                {errors?.[item.key]
                  && <FormHelperText error>
                    {getErrorValidationMessages(errors?.[item.key].type)}
                  </FormHelperText>
                }
                <BInputText
                  error={!!loginErrorMessage || !!errors?.[item.key]}
                  key={item.key}
                  name={item.key}
                  value={item.value[item.key]}
                  onChange={(e) => {
                    onChange(e.target.value.trim());
                    item.handler(e);
                    setLoginErrorMessage(null);
                  }}
                  endAdornment={
                    item.key === 'password' && item.value.password
                      ? <InputAdornment position={'end'}>
                        <IconButton
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          edge={'end'}
                          size={'small'}
                        >
                          {isShowPassword
                            ? <VisibilityOutlined fontSize={'medium'}/>
                            : <VisibilityOffOutlined fontSize={'medium'}/>
                          }
                        </IconButton>
                      </InputAdornment>
                      : null
                  }
                  {...item.options}
                  sx={{marginBottom: '24px'}}
                />
              </FormControl>
              }
            />
          ))
        }
        <BButton
          onClick={handleSubmit(handleSubmitForm)}
          type={'submit'}
          content={'Войти'}
          disabled={!!loginErrorMessage || !!Object.keys(errors).length}
        />
      </Stack>
    </Box>
  );
};
export default LoginForm;
