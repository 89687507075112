import React, {
  useEffect,
  useState,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Pagination,
  styled,
  tableCellClasses,
  TableFooter,
  tableRowClasses,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import colors from '../../styles/colors.scss';

const BTable = ({
  headers,
  rows,
  rowsPerPage,
  onPageChange,
  onRowClick,
  tableBodyMaxHeight = 'none',
  withPagination = false,
  stylesEmptyTableCaption = {},
  stylesTable = {},
  stylesTableBody = {},
  stylesTableRow = {},
  stylesRowProp = {},
}) => {
  const gridTemplateColumns = () => (headers.reduce((prev, curr) => (
    prev + (Object.prototype.hasOwnProperty.call(curr, 'width')
      ? ` ${curr.width}`
      : ' 1fr')
  ), ''));

  const defaultRowStyles = {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns(),
  };

  const [stylesRow] = useState(Object.assign(defaultRowStyles, stylesRowProp));

  const StyledTableRow = styled(TableRow)(() => ({
    [`&.${tableRowClasses.root}`]: {
      padding: '20px 8px',
      ...stylesTableRow,
    },
    [`&.${tableRowClasses.head}`]: {
      padding: '14px 8px',
    },
    boxSizing: 'border-box',
    backgroundColor: colors.white,
    ...stylesRow,
  }));

  const StyledTableCell = styled((props) => <TableCell {...props}/>)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    color: colors.greyDark,
    border: 'none',
    boxSizing: 'border-box',
    [`&.${tableCellClasses.head}`]: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
      lineHeight: '24px',
      fontSize: 18,
      borderRight: `1px solid ${colors.greyLite}`,
      '&:nth-last-of-type(1)': {
        borderRight: 'none',
      },
    },
  }));

  // PAGINATION
  const [currentTableSlice, setCurrentTableSlice] = useState([]);

  const convertedRows = withPagination
    ? currentTableSlice
    : rows;

  function onPaginationPageChange(e, page) {
    setCurrentTableSlice(rows.slice((page - 1) * rowsPerPage, page * rowsPerPage));
    onPageChange(page);
  }

  useEffect(() => {
    if (withPagination) {
      onPaginationPageChange('e', 1);
    }
  }, [rows]); // eslint-disable-line

  return (
    <>
      <TableContainer
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Table
          stickyHeader
          sx={{
            display: 'grid',
            rowGap: '12px',
            overflowY: 'hidden',
            height: '100%',
            ...stylesTable,
          }}
        >
          <TableHead>
            <StyledTableRow>
              {headers.map((header) => (
                <StyledTableCell
                  key={header.key}
                  align={header.textAlign}
                  style={{width: header.width}}
                  sx={{justifyContent: header.columnAlign || 'flex-start'}}
                >
                  {header.title}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          {convertedRows.length > 0
            ? <TableBody
              sx={{
                display: 'grid',
                rowGap: '12px',
                overflowX: 'auto',
                maxHeight: tableBodyMaxHeight,
                ...stylesTableBody,
              }}
            >
              {convertedRows.map((row) => {
                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={row.key}
                    onClick={(e) => {
                      if (onRowClick) {
                        onRowClick(row, e);
                      }
                    }}
                  >
                    {headers.map((header) => {
                      const value = row[header.key];
                      return (
                        <StyledTableCell
                          key={header.key}
                          align={header.textAlign}
                          sx={{justifyContent: header.columnAlign || 'flex-start'}}
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
              }
            </TableBody>
            : <caption style={stylesEmptyTableCaption}>
              <Typography sx={{textAlign: 'center'}} variant={'body2'}>
                Список пуст
              </Typography>
            </caption>
          }
        </Table>
      </TableContainer>
      {
        withPagination && <TableFooter
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px',
            paddingBottom: '0',
          }}
        >
          <Pagination
            count={Math.ceil(rows.length / rowsPerPage)}
            onChange={onPaginationPageChange}
          />
        </TableFooter>
      }
    </>
  );
};

BTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    key: PropTypes.string,
    width: PropTypes.string,
  })).isRequired,
  rows: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  tableBodyMaxHeight: PropTypes.string,
  stylesEmptyTableCaption: PropTypes.object,
  stylesRowProp: PropTypes.object,
  stylesTableRow: PropTypes.object,
  stylesTable: PropTypes.object,
  stylesTableBody: PropTypes.object,
  withPagination: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default BTable;
