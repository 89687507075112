import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar.jsx';

const Default = () => {
  const [open, setOpen] = useState(false);
  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Sidebar
        open={open}
        handleToggleDrawer={handleToggleDrawer}
      />
      <Outlet context={[open, setOpen]}/>
    </>
  );
};

export default Default;
