import React from 'react';
import {Navigate} from 'react-router-dom';
import Default from '../layouts/Default.jsx';
import Empty from '../layouts/empty/Empty.jsx';
import NotFound from '../pages/NotFound/NotFound.jsx';
import Login from '../pages/Login/Login.jsx';
import GroupManagement from '../pages/GroupManagement.jsx';
import ScheduleManagement from '../pages/ScheduleManagement/ScheduleManagement.jsx';
import UsersManagement from '../pages/UsersManagement.jsx';

const routes = (authed, userRole, hasPermission) => [
  {
    element: authed ? <Default/> : <Navigate to='/login'/>,
    children: [
      {
        element: <Navigate to='/calendar'/>,
        index: true,
      },
      {
        element: (hasPermission('canManageUsers')) ? <UsersManagement/> : <NotFound/>,
        path: '/user-management',
      },
      {
        element: (hasPermission('canManageGroups')) ? <GroupManagement/> : <NotFound/>,
        path: '/group-management',
      },
      {
        element: <ScheduleManagement/>,
        path: '/calendar',
      },
    ],
  },
  {
    path: 'login',
    element: !authed ? <Empty/> : <Navigate to='/'/>,
    children: [
      {
        element: <Login/>,
        index: true,
      },
    ],
  },
  {
    element: authed ? <Default/> : <Empty/>,
    children: [
      {
        element: <NotFound/>,
        path: '*',
      },
    ],
  },
];

export default routes;
