import React, {useContext, useEffect, useState} from 'react';
import {Popover, Stack} from '@mui/material';
import {isWeekend, sub} from 'date-fns';
import PropTypes from 'prop-types';
import {deepEqualObjects} from '../utils/deepEqualObjects';
import {formatTimeToCorrectDate} from '../utils/formatTime';
import {addLesson, editLesson, getSchedule} from '../services/api/apiLessons';
import BModal from './base/BModal.jsx';
import BSelect from './base/BSelect.jsx';
import BDateTimePicker from './base/BDateTimePicker.jsx';
import BCheckBox from './base/BCheckBox.jsx';
import BInputText from './base/BInputText.jsx';
import BInputTextarea from './base/BInputTextarea.jsx';
import {AlertContext} from '../contexts/Alert';
import {DAYS} from '../constants/calendar';
import {convertLessons} from '../utils/convertLessons';
import {dateIsValid} from '../utils/isDateValid';

const maxYearLessonDate = '2026-01-01';

const CreateOrEditLessonModal = ({
  currentMonthDiapason,
  optionForEditing,
  isForEditing,
  isShow,
  onClose,
  groups,
  lessonId,
  lessons,
  setLessons,
  lessonForEditing,
  lessonDateForChange,
  lessonDate,
  onLessonDateChange,
  onLessonChange,
}) => {
  const {setAlertMessage} = useContext(AlertContext);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const initialRepeatOnDays = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isRepeat, setIsRepeat] = useState(false);
  const [repeatOnDays, setRepeatOnDays] = useState(initialRepeatOnDays);
  const [repeatUntilDate, setRepeatUntilDate] = useState(null);

  const [lessonLocation, setLessonLocation] = useState('');
  const [lessonDescription, setLessonDescription] = useState('');
  const [lessonStartDate, setLessonStartDate] = useState(null);
  const [lessonEndDate, setLessonEndDate] = useState(null);
  const [isRequest, setIsRequest] = useState(false);

  const clearCreateOrEditLessonModal = () => {
    onClose();
    setLessonLocation('');
    setSelectedGroup(null);
    setLessonDescription('');
    setIsRepeat(false);
    setRepeatUntilDate(null);
    setLessonStartDate(null);
    setLessonEndDate(null);
    setRepeatOnDays(initialRepeatOnDays);
  };

  const getSelectedDaysOfWeek = (key) => (DAYS
    .filter((day) => repeatOnDays[day.name])
    .map((filteredDay) => filteredDay[key])
  );

  const validationSetLessonsData = () => {
    const errorsList = {
      selectedGroup: 'Выберите группу!',
      lessonLocation: 'Укажите локацию!',
      lessonStartDate: 'Выберите время начала занятия!',
      lessonEndDate: 'Выберите время окончания занятия!',
      notRepeatDays: 'Выберите дни недели повтора',
      notRepeatDaysRange: 'Выбранные дни недели не входят в диапазон дат!',
      notIsWeekend: 'Нельзя проводить занятия в выходные дни!',
      notRepeatUntilDate: 'Для повторяющихся занятий дата окончания обязательна!',
      wrongRepeatUntilDate: 'Дата окончания занятий не может быть позже даты начала занятий!',
      wrongLessonTimeDate: 'Неверное время или дата начала или конца урока!',
      startDateIsWrong: `Дата начала занятия не верна! Дата должна быть до ${(new Date(maxYearLessonDate)).getFullYear()} года.`,
      repeatEndDateIsWrong: `Дата окончания повторов не верна! Дата должна быть до ${(new Date(maxYearLessonDate)).getFullYear()} года.`,
    };
    let errorMessageKey;
    switch (true) {
    case !selectedGroup:
      errorMessageKey = 'selectedGroup';
      break;
    case lessonLocation.length === 0:
      errorMessageKey = 'lessonLocation';
      break;
    case !lessonStartDate:
      errorMessageKey = 'lessonStartDate';
      break;
    case !lessonEndDate:
      errorMessageKey = 'lessonEndDate';
      break;
    case (
      (new Date(`${lessonStartDate}`)).getHours() > 23
      || (new Date(`${lessonStartDate}`)).getMinutes() > 59
      || (new Date(`${lessonEndDate}`)).getHours() > 23
      || (new Date(`${lessonEndDate}`)).getMinutes() > 59
      || (
        (new Date(`${lessonStartDate}`).setMilliseconds(0)
          >= new Date(`${lessonEndDate}`).setMilliseconds(0))
      )
    ):
      errorMessageKey = 'wrongLessonTimeDate';
      break;
    case (!(dateIsValid(
      new Date(`${lessonDate}`),
      new Date(maxYearLessonDate),
    ))):
      errorMessageKey = 'startDateIsWrong';
      break;
    case (isRepeat
      && deepEqualObjects(
        repeatOnDays,
        initialRepeatOnDays,
      )):
      errorMessageKey = 'notRepeatDays';
      break;
    case (
      !isRepeat
      && isWeekend(new Date(lessonDate))
    ):
      errorMessageKey = 'notIsWeekend';
      break;
    case (
      !repeatUntilDate
      && Object.values(repeatOnDays).some((value) => value === true)
    ):
      errorMessageKey = 'notRepeatUntilDate';
      break;
    case (
      repeatUntilDate
      && (
        new Date(`${repeatUntilDate}`) < new Date(`${lessonStartDate}`)
      )
    ):
      errorMessageKey = 'wrongRepeatUntilDate';
      break;
    case (
      repeatUntilDate
      && !(dateIsValid(
        new Date(`${repeatUntilDate}`),
        new Date(maxYearLessonDate),
      ))
    ):
      errorMessageKey = 'startDateIsWrong';
      break;
    default:
      errorMessageKey = false;
      break;
    }
    if (errorMessageKey) {
      setAlertMessage({
        content: `${errorsList[errorMessageKey]}`,
        variant: 'warning',
      });
      return false;
    }
    return true;
  };

  const tryCreateOrEditLesson = async () => {
    setIsRequest(true);
    if (!validationSetLessonsData()) {
      setIsRequest(false);
      return;
    }
    const lessonStartTimeUtc = formatTimeToCorrectDate(lessonStartDate, lessonDate);
    const lessonEndTimeUtc = formatTimeToCorrectDate(lessonEndDate, lessonDate);
    const dataForRequest = {
      groupId: selectedGroup.id,
      location: lessonLocation,
      description: lessonDescription,
      isRepeat,
      lessonStartDateUtc: lessonStartTimeUtc,
      lessonStartTimeUtc,
      lessonEndTimeUtc,
      untilDateUtc: isRepeat ? repeatUntilDate : lessonEndTimeUtc,
      daysOfWeek: getSelectedDaysOfWeek('valueForRequest'),
      exdates: isForEditing ? lessonForEditing.exdates : [],
    };
    if (isForEditing) {
      switch (optionForEditing) {
      case 'all':
        await editLesson(lessonId, {
          ...lessonForEditing,
          untilDateUtc: sub(new Date(lessonDateForChange), {days: 1}),
        });
        await addLesson(dataForRequest);
        onLessonChange(currentMonthDiapason.start.toISOString(), currentMonthDiapason.end.toISOString(), true);
        break;

      case 'one':
        await editLesson(lessonId, {
          ...lessonForEditing,
          exdates: [...lessonForEditing.exdates, lessonDateForChange],
        });
        await addLesson(dataForRequest);
        onLessonChange(
          currentMonthDiapason.start.toISOString(),
          currentMonthDiapason.end.toISOString(),
          true,
        );
        break;

      default:
        await editLesson(lessonId, dataForRequest);
        // eslint-disable-next-line no-case-declarations
        const newLesson = (await getSchedule({
          startDateUtc: '',
          endDateUtc: '',
          lessonId,
          groupId: '',
        })).data[0];
        // eslint-disable-next-line no-case-declarations
        const convertedLesson = convertLessons(newLesson);
        // eslint-disable-next-line no-case-declarations
        const newLessons = lessons.map((lesson) => {
          if (lesson.id === convertedLesson.id) {
            return convertedLesson;
          }
          return lesson;
        });
        setLessons(newLessons);
        break;
      }
    } else {
      await addLesson(dataForRequest);
      onLessonChange(
        lessonStartTimeUtc,
        lessonEndTimeUtc,
        false,
      );
    }
    setIsRequest(false);
    clearCreateOrEditLessonModal();
  };

  useEffect(() => {
    if (isForEditing && lessonForEditing) {
      setLessonLocation(lessonForEditing.location);
      setSelectedGroup(lessonForEditing.group);
      setLessonDescription(lessonForEditing.description);
      setIsRepeat(lessonForEditing.isRepeat);
      setRepeatUntilDate(lessonForEditing.untilDateUtc);
      setLessonStartDate(formatTimeToCorrectDate(lessonForEditing.lessonStartTimeUtc, lessonForEditing.lessonStartDateUtc));
      setLessonEndDate(formatTimeToCorrectDate(lessonForEditing.lessonEndTimeUtc, lessonForEditing.untilDateUtc));
      if (optionForEditing === 'one') {
        setRepeatOnDays(initialRepeatOnDays);
        setIsRepeat(false);
      } else {
        const repeatedDaysOfWeek = DAYS.filter((day) => lessonForEditing.daysOfWeek
          .indexOf(day.valueForRequest) >= 0)
          .map((filtered) => filtered.name)
          .reduce((result, item) => {
            result[item] = true;
            return result;
          }, {});

        setRepeatOnDays({
          ...repeatOnDays,
          ...repeatedDaysOfWeek,
        });
      }
      onLessonDateChange(
        optionForEditing
          ? lessonDateForChange
          : lessonForEditing.lessonStartDateUtc,
      );
    } else {
      clearCreateOrEditLessonModal();
    }
  }, [ // eslint-disable-line
    isForEditing,
    lessonForEditing,
    optionForEditing,
  ]);

  return (
    <>
      <BModal
        maxWidth={'unset'}
        open={isShow}
        title={`${isForEditing ? 'Редактирование' : 'Создание'} занятия`}
        onClose={clearCreateOrEditLessonModal}
        onOutlineFooterBtnClick={clearCreateOrEditLessonModal}
        primaryFooterBtnContent={isForEditing ? 'Изменить' : 'Создать'}
        onPrimaryFooterBtnClick={tryCreateOrEditLesson}
        isPrimaryFooterBtnDisable={isRequest}
      >
        <Stack
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          spacing={0}
        >
          <p className={'modal-label'}>Выбор группы</p>
          <BSelect
            onChange={(group) => setSelectedGroup(group)}
            options={groups}
            selectedValue={selectedGroup}
            placeholder={'Выберите группу'}
            optionLabel={'name'}
            className={'mb-20'}
          />
          <p className={'modal-label'}>Время и день</p>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            className={'mb-20'}
          >
            <BDateTimePicker
              pickerType={'time'}
              value={lessonStartDate}
              onChangeDate={(date) => setLessonStartDate(date)}
              placeholder={'Время начала'}
            />
            <span>—</span>
            <BDateTimePicker
              pickerType={'time'}
              value={lessonEndDate}
              onChangeDate={(date) => setLessonEndDate(date)}
              placeholder={'Время конца'}
            />
            <BDateTimePicker
              pickerType={'date'}
              selectedDate={lessonDate}
              value={lessonDate}
              onChangeDate={(date) => onLessonDateChange(date)}
              placeholder={'Дата занятия'}
            />
            <BCheckBox
              checked={isRepeat}
              onChange={(e) => {
                setIsRepeat(e.target.checked);
                setAnchorEl(e.target.checked ? e.currentTarget : null);
              }}
              withLabel
              label={'Повторять'}
            />
          </Stack>
          <p className={'modal-label'}>Локация</p>
          <BInputText
            value={lessonLocation}
            onChange={(e) => setLessonLocation(e.target.value)}
            className={'mb-20'}
            placeholder={'Онлайн или оффлайн (номер кабинета)'}
            inputProps={{
              maxLength: 64,
            }}
          />
          <p className={'modal-label'}>Комментарий</p>
          <BInputTextarea
            placeholder={'Введите комментарий'}
            value={lessonDescription}
            onChange={(e) => setLessonDescription(e.target.value)}
            style={{paddingBottom: '2px'}}
            inputProps={{
              maxLength: 128,
            }}
          />
        </Stack>
      </BModal>
      <Popover
        PaperProps={{sx: {padding: '20px'}}}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <p className={'modal-label'}>Повтор занятий</p>
        <Stack spacing={1}>
          <Stack
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={0}
          >
            {DAYS.map((day) => <BCheckBox
              key={day.name}
              checked={repeatOnDays[day.name]}
              onChange={(e) => setRepeatOnDays({
                ...repeatOnDays,
                [day.name]: e.target.checked,
              })}
              withLabel
              label={day.labelRu}
              labelPlacement={'top'}
              labelStyles={{margin: 0}}
            />)}
          </Stack>
          <BDateTimePicker
            value={repeatUntilDate}
            onChangeDate={(date) => setRepeatUntilDate(date)}
            placeholder={'Повторять до'}
          />
        </Stack>
      </Popover>
    </>
  );
};

CreateOrEditLessonModal.propTypes = {
  onLessonChange: PropTypes.func.isRequired,
  isForEditing: PropTypes.bool,
  isShow: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  groups: PropTypes.array,
  lessons: PropTypes.array,
  optionForEditing: PropTypes.string,
  lessonForEditing: PropTypes.object,
  currentMonthDiapason: PropTypes.object,
  lessonDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  lessonDateForChange: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onLessonDateChange: PropTypes.func.isRequired,
  setLessons: PropTypes.func.isRequired,
  lessonId: PropTypes.string,
};

export default CreateOrEditLessonModal;
