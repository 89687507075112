import React from 'react';
import {TextField} from '@mui/material';
import PropTypes from 'prop-types';

const BInputTextarea = ({
  rows = 4,
  placeholder = 'Введите данные',
  ...props
}) => {
  return (
    <TextField
      fullWidth
      multiline
      rows={rows}
      placeholder={placeholder}
      {...props}
    />
  );
};

BInputTextarea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

export default BInputTextarea;
