import React from 'react';
import {Button, createTheme, ThemeProvider} from '@mui/material';
import PropTypes from 'prop-types';
import colors from '../../styles/colors.scss';

const BButton = ({
  content,
  type = 'button',
  variant = 'contained',
  color = 'primary',
  disabled = false,
  ...props
}) => {
  const buttonTheme = createTheme({
    shadows: ['none'],
    shape: {
      borderRadius: 4,
    },
    typography: {
      fontFamily: [
        'Nunito',
        'sans-serif',
      ].join(','),
      button: {
        textTransform: 'none',
        fontWeight: (color === 'primary' && !disabled) ? 700 : 600,
        fontSize: 18,
      },
    },
    palette: {
      primary: {
        main: colors.green,
        contrastText: colors.white,
        light: colors.greenMid, // hover
        dark: colors.greenMid, // active
      },
      secondary: {
        main: colors.greenLite,
        contrastText: colors.green,
        light: colors.greenLiter, // hover
        dark: colors.greenLiter, // active
      },
      action: {
        disabledBackground: colors.greyLiter, // '#F0F1F4',
        disabled: colors.greyMid,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            lineHeight: 'normal',
            minWidth: 'auto',
            maxWidth: 'unset',
          },
          outlinedPrimary: {
            color: colors.greyDark,
            borderWidth: 2,
            borderRadius: 4,
            borderColor: colors.greyDark,
            '&:hover': {
              borderRadius: '4px',
              border: `2px solid ${colors.greyDark}`,
            },
            padding: '14px 22px',
            // "&:disabled": { borderRadius: "4px", border: "1px solid black" },
            // "&.Mui-focused": { borderRadius: "4px", border: "2px solid blue" },
          },
          containedPrimary: {
            padding: '16px 24px',
          },
          containedSecondary: {
            padding: '16px 24px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        color={color}
        disabled={disabled}
        variant={variant}
        type={type}
        {...props}
      >
        {content}
      </Button>
    </ThemeProvider>
  );
};

BButton.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'contained', 'outlined',
  ]),
  color: PropTypes.oneOf([
    'primary', 'secondary',
  ]),
  type: PropTypes.string,
};

export default BButton;
