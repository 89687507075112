import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {PERMISSIONS} from '../constants/permissions';
import {getCookie} from '../services/cookie';

const permissionsContext = createContext();

function usePermissions() {
  const initRole = getCookie('role');
  const [userRole, setUserRole] = useState(initRole || null);
  const [userPermissions, setUserPermissions] = useState(initRole ? PERMISSIONS[initRole] : []);

  useEffect(() => {
    setUserPermissions(PERMISSIONS[userRole]);
  }, [userRole]);

  return {
    userPermissions,
    userRole,
    setUserRole,
    hasPermission: (permission) => userPermissions && userPermissions.includes(permission),
  };
}

export function PermissionsProvider({children}) { // eslint-disable-line
  const permissions = usePermissions();
  return <permissionsContext.Provider value={permissions}>
    {children}
  </permissionsContext.Provider>;
}

export default function PermissionsConsumer() {
  return useContext(permissionsContext);
}
