import {createTheme} from '@mui/material';
import {ruRU} from '@mui/material/locale';
import colors from '../styles/colors.scss';

const commonTheme = createTheme({
  palette: {
    error: {
      main: colors.red,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 600,
          color: colors.greyDark,
          fontFamily: [
            'Nunito',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: colors.greyDark,
          overflow: 'visible',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 24,
          textAlign: 'center',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colors.greyDark,
          backgroundColor: colors.white,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          '&.Mui-error': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      letterSpacing: 'normal',
      color: colors.greyDark,
      fontFamily: [
        'Nunito',
        'sans-serif',
      ].join(','),
    },
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
}, ruRU);

export default commonTheme;
