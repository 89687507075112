import {httpRequest} from './apiHelper';

function getGroups() {
  return httpRequest('/Group');
}

function getAllGroupsWithUsers() {
  return httpRequest('/Group/GetGroupsWithUsers');
}

function getGroupWithUsers(id) {
  return httpRequest(`/Group/${id}/GetGroupWithUsers`);
}

function createGroup(data) {
  return httpRequest('/Group', 'post', data);
}

function deleteGroup(id) {
  return httpRequest(`/Group/${id}/RemoveGroup`, 'delete');
}

function updateGroup(data) {
  return httpRequest(`/Group/${data.id}/UpdateGroup`, 'put', data);
}

function deleteUserFromGroup(data) {
  return httpRequest(`/Group/${data.groupId}/RemoveMembers`, 'put', data);
}

function addUserToGroup(data) {
  return httpRequest(`/Group/${data.groupId}/AddMembers`, 'put', data);
}

export {
  getGroups,
  getAllGroupsWithUsers,
  getGroupWithUsers,
  createGroup,
  updateGroup,
  deleteGroup,
  addUserToGroup,
  deleteUserFromGroup,
};
