import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import ru from 'date-fns/locale/ru';
import {getYear} from 'date-fns';
import colors from '../../styles/colors.scss';

// todo: style customization

const BDateTimePicker = ({
  pickerType = 'date',
  onChangeDate,
  selectedDate = null,
  maxYear = getYear(new Date()) + 3,
  minYear = getYear(new Date()),
  placeholder = 'Выберите дату',
  inputClasses = {},
  ...props
}) => {
  const [date, setDate] = useState(selectedDate);
  const handleDateChange = (date) => {
    setDate(date);
    if (date && !Number.isNaN(new Date(date).getTime())) {
      onChangeDate(date.toISOString());
    } // else { onChangeDate(null) }
  };

  const components = {
    date: DatePicker,
    dateTime: DateTimePicker,
    time: TimePicker,
  };

  const PickerComponent = components[pickerType];

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ru}
    >
      <PickerComponent
        maxDate={new Date(maxYear, 0)}
        minDate={new Date(minYear, 0)}
        value={date}
        onChange={(date) => handleDateChange(date)}
        renderInput={(params) => <TextField
          {...params}
          size={'small'}
          classes={inputClasses}
          inputProps={{
            ...params.inputProps,
            placeholder,
            sx: {
              color: colors.greyDark,
              backgroundColor: colors.white,
            },
          }}
        />
        }
        {...props}
      />
    </LocalizationProvider>
  );
};

BDateTimePicker.propTypes = {
  pickerType: PropTypes.oneOf([
    'date', 'dateTime', 'time',
  ]),
  onChangeDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  placeholder: PropTypes.string,
  maxYear: PropTypes.number,
  minYear: PropTypes.number,
  inputClasses: PropTypes.object,
};

export default BDateTimePicker;
