import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import {format, sub} from 'date-fns';
import ru from 'date-fns/locale/ru';
import {
  IconButton,
  Popover,
  Stack,
} from '@mui/material';
import {Delete, Edit, Loop} from '@mui/icons-material';
import colors from '../../styles/colors.scss';
import Calendar from '../../components/Calendar/Calendar.jsx';
import CreateOrEditLessonModal from '../../components/CreateOrEditLessonModal.jsx';
import {getAllGroupsWithUsers} from '../../services/api/apiGroups';
import {AlertContext} from '../../contexts/Alert';
import {
  addLesson,
  editLesson,
  getSchedule,
  removeLesson,
} from '../../services/api/apiLessons';
import {getTimeFromDateString} from '../../utils/formatTime';
import cl from './ScheduleManagement.module.scss';
import {convertLessons} from '../../utils/convertLessons';
import DeleteOrEditLessonModal from '../../components/DeleteOrEditLessonModal.jsx';
import usePermissions from '../../hooks/usePermissions';

const ScheduleManagement = () => {
  const {setAlertMessage} = useContext(AlertContext);
  const {hasPermission} = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);

  const [groups, setGroups] = useState([]);

  const [initialMonthDiapason, setInitialMonthDiapason] = useState({});
  const [currentMonthDiapason, setCurrentMonthDiapason] = useState({});

  const [lessons, setLessons] = useState([]);
  const [lessonDate, setLessonDate] = useState(null);
  const [lessonDateForChange, setLessonDateForChange] = useState(null);
  const [lessonId, setLessonId] = useState(null);

  const [isShowModalDeleteOrEditLesson, setIsShowModalDeleteOrEditLesson] = useState(false);

  const [isForEditing, setIsForEditing] = useState(false);
  const [lessonForEditing, setLessonForEditing] = useState(null);
  const [optionForEditing, setOptionForEditing] = useState(null);

  const [isShowModalCreateOrEditLesson, setIsShowModalCreateOrEditLesson] = useState(false);
  const clearModalCreateOrEditLesson = () => {
    setIsShowModalCreateOrEditLesson(false);
    setIsForEditing(false);
    setOptionForEditing(null);
  };

  async function getGroupsList() {
    try {
      const groups = (await getAllGroupsWithUsers()).data;
      setGroups(groups);
    } catch {
      setAlertMessage({
        content: 'Возникли проблемы с загрузкой списка групп!',
        variant: 'warning',
      });
    }
  }

  const getAllLessons = async (
    startDateUtc,
    endDateUtc,
    isUpdateAll = false,
  ) => {
    const receivedLessons = (await getSchedule({
      startDateUtc,
      endDateUtc,
      lessonId: '',
      groupId: '',
    })).data;

    const convertedLessons = receivedLessons.map((item) => convertLessons(item));
    const allLessons = [...lessons, ...convertedLessons];

    const uniqueLessons = allLessons.reduce((acc, current) => {
      const x = acc.find((item) => current.id === item.id);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
    setLessons(isUpdateAll ? convertedLessons : uniqueLessons);
  };

  const tryRemoveLesson = async (option) => {
    try {
      switch (option) {
      case 'all':
        await editLesson(lessonId, {
          id: lessonForEditing.id,
          groupId: lessonForEditing.groupId,
          location: lessonForEditing.location,
          description: lessonForEditing.description,
          isRepeat: lessonForEditing.isRepeat,
          lessonStartDateUtc: lessonForEditing.lessonStartDateUtc,
          lessonStartTimeUtc: lessonForEditing.lessonStartTimeUtc,
          lessonEndTimeUtc: lessonForEditing.lessonEndTimeUtc,
          daysOfWeek: lessonForEditing.daysOfWeek,
          exdates: lessonForEditing.exdates,
          untilDateUtc: sub(new Date(lessonDateForChange), {days: 2}),
        });
        break;

      case 'one':
        await editLesson(lessonId, {
          ...lessonForEditing,
          exdates: [...lessonForEditing.exdates, lessonDateForChange],
        });
        break;

      default:
        await removeLesson(lessonId);
        break;
      }
      getAllLessons(
        currentMonthDiapason.start.toISOString(),
        currentMonthDiapason.end.toISOString(),
        true,
      );
      setIsShowModalDeleteOrEditLesson(false);
      setAlertMessage({
        content: 'Занятие удалено!',
        variant: 'success',
      });
    } catch {
      setAlertMessage({
        content: 'Не получилось удалить занятие!',
        variant: 'warning',
      });
    }
  };

  const onCustomCalendarButtonClick = (diapason) => {
    getAllLessons(
      diapason.start.toISOString(),
      diapason.end.toISOString(),
      true,
    );
  };

  useEffect(() => {
    if (Object.keys(initialMonthDiapason).length) {
      if (hasPermission('canManageGroups')) {
        getGroupsList();
      }
      getAllLessons(
        initialMonthDiapason.start.toISOString(),
        initialMonthDiapason.end.toISOString(),
      );
    }
  }, [initialMonthDiapason]); // eslint-disable-line

  const [beforeDraggingLessonDate, setBeforeDraggingLessonDate] = useState(null);

  // CALENDAR ACTIONS
  function onDateClick(e) {
    if (hasPermission('canManageGroups')) {
      setLessonDate(e.date);
      setIsShowModalCreateOrEditLesson(true);
    }
  }

  function onEventDragStart(e) {
    if (hasPermission('canManageGroups')) {
      setBeforeDraggingLessonDate(e.event.start.toISOString());
    }
  }

  return (
    <main>
      <Calendar
        onDateClick={(e) => onDateClick(e)}
        events={lessons}
        onEventClick={async (e) => {
          setCurrentMonthDiapason({
            start: e.event._context.viewApi.activeStart,
            end: e.event._context.viewApi.activeEnd,
          });
          setLessonDateForChange(e.event.start.toISOString());
          setLessonId(e.event.id);
          const lesson = (await getSchedule({
            startDateUtc: '',
            endDateUtc: '',
            lessonId: e.event.id,
            groupId: '',
          })).data[0];
          setLessonForEditing({
            ...lesson,
            display: {
              startTime: getTimeFromDateString(e.event.startStr, false),
              endTime: getTimeFromDateString(e.event.endStr, false),
              date: format(e.event.start, 'd MMMM', {locale: ru}),
            },
          });
          setAnchorEl(e.el);
        }}
        onEventDragStart={(e) => onEventDragStart(e)}
        onEventDrop={async (e) => {
          setLessonId(e.event.id);
          const lesson = (await getSchedule({
            startDateUtc: '',
            endDateUtc: '',
            lessonId: e.event.id,
            groupId: '',
          })).data[0];
          if (lesson.isRepeat) {
            await editLesson(e.event.id, {
              ...lesson,
              exdates: [...lesson.exdates, beforeDraggingLessonDate],
            });
            await addLesson({
              groupId: lesson.groupId,
              location: lesson.location,
              description: lesson.description,
              isRepeat: false,
              daysOfWeek: [],
              exdates: [],
              untilDateUtc: null,
              lessonStartDateUtc: e.event.start.toISOString(),
              lessonStartTimeUtc: e.event.start.toISOString(),
              lessonEndTimeUtc: e.event.end.toISOString(),
            });
            await getAllLessons(
              e.event.start.toISOString(),
              e.event.end.toISOString(),
            );
          } else {
            await editLesson(e.event.id, {
              ...lesson,
              lessonStartDateUtc: e.event.start.toISOString(),
              lessonStartTimeUtc: e.event.start.toISOString(),
              lessonEndTimeUtc: e.event.end.toISOString(),
            });
          }
        }}
        onCustomNextButtonClick={onCustomCalendarButtonClick}
        onCustomPrevButtonClick={onCustomCalendarButtonClick}
        handleInitialMonthDiapason={(diapason) => setInitialMonthDiapason(diapason)}
      />
      {anchorEl
        && <Popover
          PaperProps={{sx: {padding: '20px', width: '250px'}}}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack spacing={1}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <p className={'modal-label'}>
                {lessonForEditing.display.date}, {lessonForEditing.display.startTime} - {lessonForEditing.display.endTime}
              </p>
              {lessonForEditing.isRepeat
                && <Loop sx={{color: colors.greyMid, transform: 'rotate(135deg)'}}/>}
            </Stack>
            <p className={`${cl['event-text']} ${cl['event-title']}`}>
              Группа: <span className={cl['event-text']}>
                {lessonForEditing.group.name}
              </span>
            </p>
            <p className={`${cl['event-text']} ${cl['event-title']}`}>
              Локация: <span className={cl['event-text']}>
                {lessonForEditing.location}
              </span>
            </p>
            {
              lessonForEditing.description
              && <p className={`${cl['event-text']} ${cl['event-title']}`}>
                Комментарий: <span className={cl['event-text']}>
                  {lessonForEditing.description}
                </span>
              </p>
            }
            {
              hasPermission('canManageGroups')
              && <>
                <Stack
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <IconButton
                    onClick={() => {
                      setIsForEditing(true);
                      if (lessonForEditing.isRepeat) {
                        setIsShowModalDeleteOrEditLesson(true);
                      } else {
                        setIsShowModalCreateOrEditLesson(true);
                      }
                      setAnchorEl(null);
                    }}
                  >
                    <Edit sx={{color: colors.greyMid}}/>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsShowModalDeleteOrEditLesson(true);
                      setAnchorEl(null);
                    }}
                  >
                    <Delete sx={{color: colors.greyMid}}/>
                  </IconButton>
                </Stack>
              </>
            }
          </Stack>
        </Popover>
      }
      {lessonForEditing
        && <DeleteOrEditLessonModal
          isForEditing={isForEditing}
          isShow={isShowModalDeleteOrEditLesson}
          onClose={() => {
            setIsShowModalDeleteOrEditLesson(false);
            setIsForEditing(false);
            setOptionForEditing(null);
          }}
          onLessonChange={(option) => {
            if (isForEditing) {
              setOptionForEditing(option);
              setIsShowModalDeleteOrEditLesson(false);
              setIsShowModalCreateOrEditLesson(true);
            } else {
              tryRemoveLesson(option);
            }
          }}
          isLessonRepeat={lessonForEditing.isRepeat}
        />
      }
      <CreateOrEditLessonModal
        currentMonthDiapason={currentMonthDiapason}
        lessonDateForChange={lessonDateForChange}
        optionForEditing={optionForEditing}
        isForEditing={isForEditing}
        lessonForEditing={lessonForEditing}
        isShow={isShowModalCreateOrEditLesson}
        onClose={clearModalCreateOrEditLesson}
        groups={groups}
        lessonId={lessonId}
        lessons={lessons}
        lessonDate={lessonDate}
        onLessonDateChange={(date) => setLessonDate(date)}
        onLessonChange={getAllLessons}
        setLessons={setLessons}
      />
    </main>
  );
};

export default ScheduleManagement;
