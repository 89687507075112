import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {InputBase} from '@mui/material';

const EnglishLevelInput = ({
  initialEnglishLevel = '',
  onEnglishLevelBlur,
  ...props
}) => {
  const [userEnglishLevel, setUserEnglishLevel] = useState(initialEnglishLevel);
  return (
    <InputBase
      inputProps={{maxLength: 26}}
      value={userEnglishLevel}
      onChange={(e) => setUserEnglishLevel(e.target.value)}
      onBlur={() => {
        if (userEnglishLevel.trim()) {
          onEnglishLevelBlur(userEnglishLevel);
        } else {
          setUserEnglishLevel(initialEnglishLevel);
        }
      }}
      fullWidth
      size='small'
      placeholder='Введите значение'
      { ...props}
    />
  );
};

EnglishLevelInput.propTypes = {
  onEnglishLevelBlur: PropTypes.func.isRequired,
  initialEnglishLevel: PropTypes.string,
};

export default EnglishLevelInput;
