const ROLES = {
  master: 'MASTER',
  user: 'USER',
};

const SCOPES = {
  canManageUsers: 'canManageUsers',
  canManageGroups: 'canManageGroups',
};

const PERMISSIONS = {
  [ROLES.master]: [
    SCOPES.canManageUsers,
    SCOPES.canManageGroups,
  ],
  [ROLES.user]: [],
};

export {
  ROLES,
  SCOPES,
  PERMISSIONS,
};
