import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import ru from '@fullcalendar/core/locales/ru';
import cl from './Calendar.module.scss';
import colors from '../../styles/colors.scss';
import usePermissions from '../../hooks/usePermissions';

const Calendar = ({
  onDateClick,
  onEventClick,
  onEventDrop,
  onEventDragStart,
  onCustomPrevButtonClick,
  onCustomNextButtonClick,
  handleInitialMonthDiapason,
  events = [],
  ...props
}) => {
  const {hasPermission} = usePermissions();
  const renderEventContent = (eventInfo) => {
    return (
      <Stack
        direction={'row'}
        flexGrow={1}
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{
          cursor: 'pointer',
          width: '100%',
          background: 'inherit',
          color: colors.greyDark,
          '& p': {
            margin: 0,
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      >
        {
          hasPermission('canManageGroups')
          && <span
            style={{
              background: eventInfo.backgroundColor,
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              flex: '0 0 auto',
              marginRight: '8px',
            }}
          />
        }
        <Stack>
          <p style={{marginRight: '16px'}}>
            {eventInfo.event.title}
          </p>
          <p style={{color: colors.greyMid}}>
            {eventInfo.timeText}
          </p>
        </Stack>
      </Stack>
    );
  };

  const calendarRef = useRef();

  const onCustomButtonClick = (method) => {
    const api = calendarRef.current.getApi();
    api[method]();
    return {
      start: api.view.activeStart,
      end: api.view.activeEnd,
    };
  };

  useEffect(() => {
    const api = calendarRef.current.getApi();
    handleInitialMonthDiapason({
      start: api.view.activeStart,
      end: api.view.activeEnd,
    });
  }, []); // eslint-disable-line

  return (
    <div className={`${cl.calendar} ${cl.calendar_default}`}>
      <FullCalendar
        ref={calendarRef}
        eventOverlap={false}
        slotEventOverlap={false}
        plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
        locale={ru}
        // titleFormat={{year: '2-digit', month: 'long'}}
        dayHeaderFormat={{weekday: 'long'}}
        headerToolbar={{
          left: 'customPrev title customNext',
          center: '',
          right: '', // today // 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        customButtons={{
          customPrev: {
            icon: 'chevron-left',
            click() {
              const diapason = onCustomButtonClick('prev');
              onCustomPrevButtonClick(diapason);
            },
          },
          customNext: {
            icon: 'chevron-right',
            click() {
              const diapason = onCustomButtonClick('next');
              onCustomNextButtonClick(diapason);
            },
          },
        }}
        displayEventEnd
        stickyHeaderDates={false}
        initialView={'dayGridMonth'}
        // weekNumbers
        // weekNumberFormat={{week: 'numeric'}}
        weekends
        contentHeight={'auto'}
        height={'100%'}
        eventContent={renderEventContent}
        eventStartEditable={hasPermission('canManageGroups')}
        eventDurationEditable={false}
        eventDrop={onEventDrop}
        eventDragStart={onEventDragStart}
        dateClick={(e) => {
          const isWeekend = e.date.getDay() === 6 || e.date.getDay() === 0;
          // disable click on weekends
          if (!isWeekend) {
            onDateClick(e);
          }
        }}
        eventAllow={(dropInfo) => {
          // disable dragging to weekends
          const isWeekend = dropInfo.start.getDay() === 6 || dropInfo.start.getDay() === 0;
          return !isWeekend;
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
        dayMaxEventRows={3}
        events={events}
        eventClick={onEventClick}
        timeZone={'local'}
        {...props}
      />
    </div>);
};

Calendar.propTypes = {
  events: PropTypes.array,
  onEventDragStart: PropTypes.func.isRequired,
  onEventDrop: PropTypes.func.isRequired,
  onDateClick: PropTypes.func.isRequired,
  onEventClick: PropTypes.func.isRequired,
  onCustomPrevButtonClick: PropTypes.func.isRequired,
  onCustomNextButtonClick: PropTypes.func.isRequired,
  handleInitialMonthDiapason: PropTypes.func.isRequired,
};

export default Calendar;
