import React, {useState} from 'react';
import PropTypes from 'prop-types';
import BModal from './base/BModal.jsx';
import BRadio from './base/BRadio.jsx';

const DeleteOrEditLessonModal = ({
  isForEditing,
  isLessonRepeat,
  isShow,
  onClose,
  onLessonChange,
}) => {
  const options = [
    {
      value: 'one',
      label: 'Только это занятие',
    },
    {
      value: 'all',
      label: 'Это и все последующие занятия',
    },
  ];

  const initialOption = options[0].value;
  const [selectedOption, setSelectedOption] = useState(initialOption);

  const clearDeleteLessonModal = () => {
    onClose();
    setSelectedOption(initialOption);
  };

  return (
    <BModal
      open={isShow}
      onClose={clearDeleteLessonModal}
      title={isForEditing ? 'Выберите редактируемые занятия' : 'Вы уверены, что хотите удалить данное занятие?'}
      titleStyles={isLessonRepeat ? {} : {marginBottom: 0}}
      buttonAlignment={'center'}
      onOutlineFooterBtnClick={clearDeleteLessonModal}
      primaryFooterBtnContent={isForEditing ? 'Продолжить' : 'Удалить'}
      onPrimaryFooterBtnClick={() => onLessonChange(isLessonRepeat ? selectedOption : null)}
    >
      {isLessonRepeat
        && <BRadio
          radioOptions={options}
          onChange={(e) => setSelectedOption(e.target.value)}
          selectedValue={selectedOption}
        />
      }
    </BModal>
  );
};

DeleteOrEditLessonModal.propTypes = {
  isForEditing: PropTypes.bool.isRequired,
  isLessonRepeat: PropTypes.bool.isRequired,
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLessonChange: PropTypes.func.isRequired,
};

export default DeleteOrEditLessonModal;
