import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import {Stack, Typography} from '@mui/material';
import {PersonRemove} from '@mui/icons-material';
import BButton from '../components/base/BButton.jsx';
import BModal from '../components/base/BModal.jsx';
import BInputText from '../components/base/BInputText.jsx';
import BTable from '../components/base/BTable.jsx';
import Group from '../components/Group/Group.jsx';
import {getUsers} from '../services/api/apiUsers';
import {
  addUserToGroup,
  deleteGroup,
  deleteUserFromGroup,
  getAllGroupsWithUsers,
  createGroup,
  getGroupWithUsers,
} from '../services/api/apiGroups';
import {AlertContext} from '../contexts/Alert';
import BSelect from '../components/base/BSelect.jsx';
import colors from '../styles/colors.scss';
import {MAX_LENGTH_GROUP_NAME} from '../constants/groups';
import {SERVERERRORSMESSAGES} from '../constants/errors';

const GroupManagement = () => {
  const {setAlertMessage} = useContext(AlertContext);

  const [users, setUsers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupInitialName, setGroupInitialName] = useState('');
  const [groupMembersCurrentGroup, setGroupMembersCurrentGroup] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedGroupName, setSelectedGroupName] = useState('');

  // GET DATA
  const getUsersData = async () => {
    try {
      const users = (await getUsers()).data;
      setUsers(users);
    } catch {
      setAlertMessage({
        content: 'Возникли проблемы с загрузкой пользователей!',
        variant: 'warning',
      });
    }
  };

  const getGroupsData = async () => {
    try {
      const groups = (await getAllGroupsWithUsers()).data;
      setGroups(groups);
    } catch {
      setAlertMessage({
        content: 'Возникли проблемы с загрузкой списка групп!',
        variant: 'warning',
      });
    }
  };

  // SELECT FOR GROUP MEMBERS
  const [selectedUsers, setSelectedUsers] = useState([]);

  const usersForSelect = useMemo(() => {
    return users
      .filter((user) => !groupMembersCurrentGroup.some(((member) => member.id === user.id)));
  }, [
    users,
    groupMembersCurrentGroup,
  ]);

  // MODAL CREATE GROUP
  const [isShowModalCreateGroup, setIsShowModalCreateGroup] = useState(false);
  const clearModalCreateGroup = () => {
    setIsShowModalCreateGroup(false);
    setGroupInitialName('');
  };

  // MODAL EDIT GROUP MEMBERS
  const [isShowModalEditGroupMembers, setIsShowModalEditGroupMembers] = useState(false);
  const clearModalEditGroupMembers = () => {
    setIsShowModalEditGroupMembers(false);
    setSelectedUsers([]);
    setSelectedGroupId('');
  };

  async function onAddUserToGroup() {
    try {
      if (selectedUsers.length > 0) {
        await addUserToGroup({
          groupId: selectedGroupId,
          userIds: selectedUsers.map((user) => user.id),
        });
        setSelectedUsers([]);
        await getGroupsData();
        setAlertMessage({
          content: 'Пользователи успешно добавлены!',
          variant: 'success',
        });
      } else {
        setAlertMessage({
          content: 'Ни одного пользователя не выбрано!',
          variant: 'warning',
        });
      }
    } catch (error) {
      setAlertMessage({
        content: SERVERERRORSMESSAGES[error.response.data.statusCode],
        variant: 'warning',
      });
    }
  }

  // MODAL DELETE GROUP
  const [isShowModalDeleteGroup, setIsShowModalDeleteGroup] = useState(false);

  const tryCreateGroup = async () => {
    const newGroupInitialName = groupInitialName.trim();
    if (!newGroupInitialName) {
      setAlertMessage({
        content: 'Введите название группы!',
        variant: 'warning',
      });
      return;
    }
    try {
      await createGroup({name: newGroupInitialName});
      clearModalCreateGroup();
      await getGroupsData();
      setAlertMessage({
        content: 'Группа успешно создана!',
        variant: 'success',
      });
    } catch (error) {
      setAlertMessage({
        content: SERVERERRORSMESSAGES[error.response.data.statusCode],
        variant: 'warning',
      });
    }
  };

  const tryDeleteGroup = async (groupId) => {
    try {
      await deleteGroup(groupId);
      await getGroupsData();
      setIsShowModalDeleteGroup(false);
      setAlertMessage({
        content: 'Группа удалена!',
        variant: 'success',
      });
    } catch {
      setAlertMessage({
        content: 'Что-то пошло не так!',
        variant: 'warning',
      });
    }
  };

  const getSelectedGroupName = async (groupId) => {
    const name = (await getGroupWithUsers(groupId)).data.name;
    setSelectedGroupName(name);
  };

  const GroupsForRender = useMemo(() => {
    if (groups && groups.length > 0) {
      return groups.map((group) => <Group
        key={group.id}
        groupId={group.id}
        initialName={group.name}
        numberOfMembers={group.users.length}
        onGroupNameBlur={getGroupsData}
        onButtonDeleteClick={() => {
          setSelectedGroupId(group.id);
          getSelectedGroupName(group.id);
          setIsShowModalDeleteGroup(true);
        }}
        onButtonMembersClick={() => {
          setSelectedGroupId(group.id);
          getSelectedGroupName(group.id);
          setIsShowModalEditGroupMembers(true);
        }}
      />);
    }
    return <></>;
  }, [groups]); // eslint-disable-line

  // GROUP MEMBERS TABLE
  const headers = [
    {
      title: '№',
      key: 'number',
      width: '50px',
      columnAlign: 'center',
    },
    {
      title: 'Имя Фамилия',
      key: 'name',
    },
    // todo: english level
    // {
    //   title: 'Уровень английского',
    //   key: 'englishLevel',
    //   width: '250px',
    //   columnAlign: 'center',
    // },
    {
      key: 'actions',
      title: 'Действия',
      width: '150px',
      columnAlign: 'center',
    },
  ];

  const groupMembers = useMemo(() => {
    if (groupMembersCurrentGroup.length > 0) {
      return groupMembersCurrentGroup.map((user, index) => {
        return {
          key: user.id,
          number: index + 1,
          name: user.fullName,
          actions: <BButton
            sx={{padding: '12px'}}
            content={<PersonRemove fontSize={'small'}/>}
            onClick={async () => {
              await deleteUserFromGroup({
                groupId: selectedGroupId,
                userIds: [user.id],
              });
              await getGroupsData();
            }}
          />,
        };
      });
    }
    return [];
  }, [groupMembersCurrentGroup]); // eslint-disable-line

  useEffect(() => {
    getGroupsData();
    getUsersData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedGroupId && isShowModalEditGroupMembers) {
      const members = (groups.find((group) => group.id === selectedGroupId)).users;
      setGroupMembersCurrentGroup(members);
    }
  }, [groups, selectedGroupId, isShowModalEditGroupMembers]);

  return (
    <main>
      <Typography variant={'h2'} sx={{marginBottom: '30px'}}>
        Список групп
      </Typography>
      <BButton
        onClick={() => setIsShowModalCreateGroup(true)}
        content={'Создать группу'}
        sx={{
          marginBottom: '25px',
          maxWidth: 'max-content',
          padding: '8px 16px',
        }}
      />
      <div
        style={{
          gridGap: '20px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          marginBottom: '25px',
        }}
      >
        {GroupsForRender}
      </div>
      <BModal
        open={isShowModalCreateGroup}
        onClose={clearModalCreateGroup}
        title={'Создание группы'}
        onOutlineFooterBtnClick={clearModalCreateGroup}
        onPrimaryFooterBtnClick={tryCreateGroup}
      >
        <p className={'modal-label'}>Введите название</p>
        <BInputText
          isAutoFocus={true}
          inputProps={{maxLength: MAX_LENGTH_GROUP_NAME}}
          value={groupInitialName}
          onChange={(e) => setGroupInitialName(e.target.value)}
          placeholder={'Название группы'}
          onKeyPress={(e) => (e.key === 'Enter') && tryCreateGroup()}
        />
      </BModal>
      <BModal
        maxWidth={'unset'}
        open={isShowModalEditGroupMembers}
        onClose={clearModalEditGroupMembers}
        title={`Редактирование участников группы "${selectedGroupName}"`}
        withFooterButtons={false}
      >
        <Stack
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          spacing={0}
        >
          <p className={'modal-label'}>Добавление участников</p>
          <Stack
            direction={'row'}
            sx={{width: '100%', marginBottom: '20px'}}
            spacing={0}
          >
            <BSelect
              isMultiple
              isVirtual
              optionLabel={'fullName'}
              selectStyles={{marginRight: '10px'}}
              placeholder={'Выбор участников'}
              selectAllLabel={'Выбрать всех'}
              selectedValues={selectedUsers}
              onChange={(e) => setSelectedUsers(e)}
              options={usersForSelect}
            />
            <BButton
              onClick={() => onAddUserToGroup()}
              content={'Добавить'}
              sx={{padding: '8px 16px'}}
            />
          </Stack>
          <p className={'modal-label'}>Список всех участников</p>
          <BTable
            headers={headers}
            rows={groupMembers}
            stylesEmptyTableCaption={{borderTop: `1px solid ${colors.greyLite}`}}
            stylesRowProp={{borderTop: `1px solid ${colors.greyLite}`}}
            stylesTableRow={{padding: '8px'}}
            stylesTableBody={{rowGap: 0}}
            stylesTable={{rowGap: 0}}
          />
        </Stack>
      </BModal>
      <BModal
        open={isShowModalDeleteGroup}
        onClose={() => setIsShowModalDeleteGroup(false)}
        onOutlineFooterBtnClick={() => setIsShowModalDeleteGroup(false)}
        primaryFooterBtnContent={'Удалить'}
        onPrimaryFooterBtnClick={() => tryDeleteGroup(selectedGroupId)}
        title={`Вы уверены, что хотите удалить группу "${selectedGroupName}"?`}
        titleStyles={{marginBottom: 0}}
        buttonAlignment={'center'}
      >
      </BModal>
    </main>
  );
};

export default GroupManagement;
