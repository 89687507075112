import React, {useEffect, useState} from 'react';
import {Snackbar, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import cl from './BAlert.module.scss';
import alertIconSuccess from '../../../assets/alertIconSuccess.svg';
import alertIconWarning from '../../../assets/alertIconWarning.svg';

const BAlert = ({
  message = {},
  clearContent,
}) => {
  const [isAlertShow, setIsAlertShow] = useState(false);

  const {
    content,
    variant,
    autoHideDuration = 2500,
  } = message;

  const alertVariants = {
    success: {
      icon: alertIconSuccess,
    },
    warning: {
      icon: alertIconWarning,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsAlertShow(false);
    }, autoHideDuration);
    clearContent();
  }, [setIsAlertShow, autoHideDuration]); // eslint-disable-line

  useEffect(() => {
    if (content) {
      setIsAlertShow(true);
    }
  }, [content, clearContent]);

  return (
    <Snackbar
      open={isAlertShow}
      onClose={(event, reason) => {
        if (reason === 'timeout') {
          setIsAlertShow(false);
          clearContent();
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={autoHideDuration}
    >
      <div className={cl.alert}>
        {variant
          && <div className={`${cl.alert__icon} ${cl[`alert__icon_${variant}`]}`}>
            <img
              src={alertVariants[variant].icon}
              alt={`${variant} icon`}
            />
          </div>
        }
        <Typography variant={'h3'}>
          {content}
        </Typography>
      </div>
    </Snackbar>
  );
};

BAlert.propTypes = {
  clearContent: PropTypes.func,
  message: PropTypes.shape({
    content: PropTypes.string,
    autoHideDuration: PropTypes.number,
    variant: PropTypes.oneOf([
      'success', 'warning',
    ]),
  }),
};

export default BAlert;
