import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';

const BRadio = ({
  selectedValue,
  radioOptions,
  onChange,
  optionKeyProp = 'value',
  optionValueProp = 'value',
  optionLabelProp = 'label',
  labelPlacement = 'end',
  ...props
}) => {
  return (
    <FormControl>
      <RadioGroup
        value={selectedValue}
        onChange={onChange}
      >
        {radioOptions.map((radio) => <FormControlLabel
          control={<Radio/>}
          value={radio[optionValueProp]}
          label={radio[optionLabelProp]}
          key={radio[optionKeyProp]}
          labelPlacement={labelPlacement}
          {...props}
        />)
        }
      </RadioGroup>
    </FormControl>
  );
};

BRadio.propTypes = {
  selectedValue: PropTypes.string,
  radioOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  labelPlacement: PropTypes.oneOf([
    'top', 'start', 'bottom', 'end',
  ]),
  optionValueProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  optionKeyProp: PropTypes.string,
};

export default BRadio;
