import {httpRequest} from './apiHelper';

function getUsers() {
  return httpRequest('/User');
}

function putUser(user) {
  return httpRequest(
    `/User/${user.id}`,
    'put',
    {...user},
  );
}

export {
  getUsers,
  putUser,
};
