import {format, set} from 'date-fns';

const getTimeFromDateString = (dateString, withSeconds = true) => {
  const formatString = `HH:mm${withSeconds ? ':ss' : ''}`;
  return format(new Date(dateString), formatString);
};

const formatTimeToCorrectDate = (correctTimeString, correctDateString) => {
  const time = getTimeFromDateString(correctTimeString).split(':');
  const date = set(new Date(correctDateString), {
    hours: time[0],
    minutes: time[1],
    seconds: time[2],
  });
  return date.toISOString();
};

export {
  formatTimeToCorrectDate,
  getTimeFromDateString,
};
