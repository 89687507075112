import React from 'react';
import {Typography} from '@mui/material';
import cl from './NotFound.module.scss';
import '../../styles/common.scss';

const NotFound = () => <main className={`${cl.container} background-gradient`}>
  <div className={cl.wrapper}>
    <div className={cl['not-found']}>
      <Typography
        variant={'h1'}
        sx={{
          fontSize: '28px',
          lineHeight: '26px',
          marginBottom: '23px',
        }}
      >
        Страница не найдена :(
      </Typography>
      <Typography variant={'body2'}>
        Обратитесь в службу поддержки
      </Typography>
    </div>
  </div>
</main>;

export default NotFound;
