import React, {createContext, useContext, useState} from 'react';
import {getCookie, setCookie, deleteCookie} from '../services/cookie';
import {login} from '../services/api/apiAuthorization';
import usePermissions from './usePermissions';

const authContext = createContext();

function useAuth() {
  const token = getCookie('accessToken');
  const {setUserRole} = usePermissions();
  const [authed, setAuthed] = useState(!!token);

  return {
    authed,
    login(data) {
      return login(data)
        .then((response) => {
          const role = response.data.role.toUpperCase();
          setCookie('accessToken', response.data.accessToken);
          setCookie('refreshToken', response.data.refreshToken);
          setAuthed(true);
          setCookie('role', role);
          setUserRole(role);
        });
    },
    logout() {
      deleteCookie('username');
      deleteCookie('refreshToken');
      deleteCookie('accessToken');
      setAuthed(false);
      deleteCookie('role');
      setUserRole('');
    },
  };
}

export function AuthProvider({children}) { // eslint-disable-line
  const auth = useAuth();
  return <authContext.Provider value={auth}>
    {children}
  </authContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(authContext);
}
